@font-face {
  font-family: 'BebasNeue';
  src: url('../public//fonts/BebasNeue-Regular.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'BebasNeue', 'Helvetica Neue', sans-serif;
}

body::-webkit-scrollbar {
  width: 0em; /* Ajustez la largeur de la barre de défilement */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* Couleur transparente pour masquer la barre de défilement */
}
