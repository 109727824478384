.contact:before {
    content: '';
    display: block;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 5vw;
    background: repeat-x center top var(--white);
}

.contact {
    position: sticky;
    top: 0;
    background-color: var(--white);
    color: var(--black);
    text-align: center;
    z-index: 1;
    padding-bottom: 10vw;
}

.button > span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    display: inline-block;
    white-space: nowrap;
    background-color: inherit;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .1s ease;
}