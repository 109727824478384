.nav {
    display: block;
    width: 100%;
    height: auto;
    z-index: 99;
    position: absolute;
}

.nav__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 100px;
}

.full {
    padding-left: 60px;
    padding-right: 60px;
}

.nav__desktop {
    color: var(--white);
    width: 100%;
    text-align: left;
    margin: 0 auto;
    list-style-type: none;
}

.nav__desktop--left {
    padding-right: 50px;
}

.nav__desktop--right {
    padding-left: 50px;
}

.navbar-hover:hover {
    color: var(--pink);
}



.nav__desktop--left li:first-child {
    margin-left: 0;
}

.nav__desktop--right li:last-child {
    margin-right: 0;
}

.nav__desktop > li {
    font-weight: 600;
    margin: 0 auto;
}

.button {
    position: relative;
    font-weight: 800;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    text-decoration: none;
    background-color: var(--pink);
    color: var(--black);
    padding: .2em .7em .6em .7em;
    letter-spacing: .01em;
    text-transform: uppercase;
    box-shadow: none;
    border-radius: 4px;
}

.button > span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    display: inline-block;
    white-space: nowrap;
    background-color: inherit;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .1s ease;
}

.button:hover > span {
    opacity: 1;
    transition: opacity .1s ease;
}

.button > span > span {
    display: inline-block;
    text-align: center;
    animation: marquee 1s linear infinite;
    padding: 0.2em 0.7em 0.6em 0.7em;
}

.nav__desktop li.button {
    padding: 0.4em 0.7em 0.4em 0.7em;
    background-color: var(--pink);
}

.nav__desktop li.button a {
    font-weight: 800;
    padding: 0;
    text-shadow: none;
}

.nav__desktop li.button > span span {
    padding: 0.3em 0.7em 0.6em 0.7em;
}

.nav__desktop li a {
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    text-decoration: none;
    padding: 0.5em 0;
}

.site-logo {
    display: block;
    top: 0;
    left: 0;
    width: 113px;
    margin: 16px auto 0 auto;
    flex-shrink: 0;
}

.site-logo svg {
    width: 100%;
    height: auto;
}

.nav__icon {
    display: none;
    background-color: var(--pink);
    border-radius: 4px;;
    color: var(--white);
    padding: 0 10px;
    text-transform: uppercase;
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 11;
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: top .3s ease;
    border: 0;
    list-style-type: none;
}

.nav__icon div {
    width: 100%;
    height: auto;
    display: block;
    align-self: center;
}

.nav__icon div span {
    background-color: var(--black);
    display: block;
    height: 3px;
    margin-bottom: 4px;
    transform-origin: center center;
    transition: all .3s ease;
}

.nav__mobile {
    color: var(--black);
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    pointer-events: none;
    list-style-type: none;
    background-color: var(--pink);
}

.nav__mobile li:first-child {
    margin-top: auto;
}

.nav__mobile li:last-child {
    margin-bottom: auto;
}

.nav__mobile li a {
    display: block;
    font-size: 13vw;
    font-weight: 900;
    line-height: .9em;
    text-transform: uppercase;
    letter-spacing: .01em;
    text-decoration: none;
    height: 1em;
    transition: all .3s ease;
    padding: 0;
}

.icon-list {
    position: fixed;
    z-index: 50;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 25px;
    list-style-type: none;
    justify-content: center;
}

.icon-list li a {
    width: 1.3em;
    height: 1.3em;
    color: var(--black);
    background-image: url('../../../public/icons/linkedin.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    text-align: center;
    text-indent: -999px;
    overflow: hidden;
}

@media (min-width: 768px) {
    .nav__desktop {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
    }

    .nav__desktop li {
        display: inline-block;
    }

    .nav__desktop--right {
        text-align: right;
        margin: 0 auto;
        justify-content: flex-end;
        padding-left: 50px;
    }

    .site-logo {
        width: 181px;
    }

    .nav__mobile {
        display: none !important;
    }

    .icon-list {
        display: none !important;
    }

    .nav__icon {
        display: none !important;
    }
}

@media (max-width: 767px) {

    .nav--open {
        background-color: var(--pink);
        transition: all .3s ease;
        height: 100%;
    }

    .nav__content {
        height: 60px;
    }

    .nav__desktop {
        display: none;
    }

    .nav__desktop--right {
        display: none;
    }

    .nav__icon {
        display: flex;
    }

    .nav__mobile {
        display: flex;
        opacity: 1;
        pointer-events: auto;
    }

    .nav--open {
        fill: var(--black);
    }

    .nav--open .nav .icon-list {
        display: flex;
        justify-content: center;
    }
    
}

@media (min-width: 1240px) {
    .nav__content {
        height: 120px;
    }

    .full {
        padding-left: 107px;
        padding-right: 107px;
    }

    .button {
        font-size: 24px;
    }

    li {
        font-size: 22px;
    }

    .site-logo {
        width: 184px;
    }
    
}
