footer:before {
    content: '';
    display: block;
    position: absolute;
    width: 150vw;
    left: 50%;
    top: 0;
    height: 0;
    padding-bottom: 132%;
    transform: translateX(-50%);
    background-color: var(--pink);
    border-radius: 150%;
    z-index: 2;
}

footer:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--pink);
    z-index: 1;
    box-sizing: inherit;
}

footer {
    display: block;
    width: 100%;
    color: var(--white);
    clear: both;
    padding-top: 30px;
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;
    text-align: center;
    transform: translateY(50px);
    transition: transform .4s .2s cubic-bezier(.5,1.51,.55,.89);
    z-index: 90;
}

footer.loaded {
    transform: translateY(0);
    transition: transform .4s .2s cubic-bezier(.5,1.51,.55,.89);
}

footer .full {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;
}

.footer__content {
    margin: 0 auto;
    z-index: 2;
    position: relative;
    margin-bottom: 20px;
}

.footer__content .hidden {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 30px;
}

.footer__content.footer__content--last {
    flex-wrap: wrap;
    border-top: 2px solid var(--black);
    padding-top: 20px;
    background-color: var(--pink);
}

.contactform {
    z-index: 2;
    position: relative;
    width: 100vw;

}

form {
    display: flex;
    flex-direction: column;
}

.nameEmailDiv {
    display: flex;
    width: 100%;
}
  
  .contactform input[type="text"],
  .contactform input[type="email"] {
    font-family: 'BebasNeue';
    font-size: 2vw;
    color: var(--black);
    border: none;
    padding: 15px;
    width: calc(45vw - 40px);
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }

  .contactform input[type="text"] {
    margin-left: auto;
    margin-right: 20px;
  }

  .contactform input[type="email"] {
    margin-right: auto;
    margin-left: 20px;
  }

  .contactform textarea {
    font-family: 'BebasNeue';
    font-size: 2vw;
    color: var(--black);
    width: calc(90vw - 10px);
    margin: auto;
    padding: 15px;
    border: none;
    border-radius: 4px;
    resize: none;
    background-color: var(--white);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .contactform input[type="submit"] {
    padding: 10px;
    border: none;
    font-family: 'BebasNeue';
    font-size: 2vw;
    border-radius: 4px;
    background-color: var(--black);
    color: var(--white);
    font-weight: bold;
    cursor: pointer;
    margin: 20px auto;
    transition: background-color 0.3s ease;
  }
  
  .contactform input[type="submit"]:hover {
    background-color: var(--lightgreen);
  }

  .emailValidated {
    display: none;
  }

  .emailNonValidated {
    background-color: var(--black);
    padding: 10px;
    width: 90vw;
    margin: 20px auto;
    font-size: 1.5vw;
    border-radius: 4px;
}

.icon-footer {
    display: flex;
    align-items: center;
}

.icon-footer-li {
    list-style: none;
}

.footer__credits {
    font-size: 18px;
}

@media (max-width: 767px) {
    .contactform input[type="text"],
    .contactform input[type="email"] {
        width: 80vw;
        margin-bottom: 20px;
        font-size: 5vw;
    }

    .contactform input[type="text"] {
        margin-left: auto;
        margin-right: auto;
    }
    
    .contactform input[type="email"] {
        margin-right: auto;
        margin-left: auto;
    }

    .contactform textarea {
        width: 80vw;
        font-size: 5vw;
    }

    .nameEmailDiv {
        flex-direction: column;
    }
}

@media (min-width: 768px) {

    .contactform input[type="text"],
    .contactform input[type="email"] {
      margin-bottom: 20px;
    }


    .footer__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: top;
        font-size: .9rem;
    }
}

@media (min-width: 1024px) {
    .footer__content .hidden {
        margin-bottom: 60px;
    }
}

@media (min-width: 1240px) {
    footer__credits {
        font-size: 22px;
    }
}


