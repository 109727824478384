.intro {
    width: 100%;
    height: auto;
    min-height: 100vh;
    text-align: center;
    position: relative;
    background-color: var(--black);
    color: var(--white);
}

.intro .headerFull {
    height: 100%;
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 120px;
    padding-bottom: 120px;
    max-width: 100vw;
    box-sizing: border-box;
    align-items: center;
}

.headerFull {
    width: 100%;
    padding-left: 107px;
    padding-right: 107px;
}

.appear {
    opacity: 0;
    transform: scale(0);
    transition: all .5s cubic-bezier(.5,1.51,.55,.89);
}

header.loaded .appear.delay:nth-child(2) {
    transition-delay: .2s;
}

header.loaded .appear.delay:nth-child(3) {
    transition-delay: .4s;
}

header.loaded .appear {
    opacity: 1;
    transform: scale(1);
    transition: all .5s cubic-bezier(.5,1.51,.55,.89);
}

.intro h1 {
    font-size: 30vw;
    position: relative;
    letter-spacing: -.01em;
}

.intro__title1 {
    margin-top: auto;
    margin-bottom: -20px;
    pointer-events: none;
}

.intro__title2 {
    margin-top: -80px;
    margin-bottom: auto;
    pointer-events: none;
}

.intro__image {
    position: relative;
}

.intro__image img {
    width: 100%;
}

.noselect {
    user-select: none;
  }

@media (max-width: 767px) {

    .intro .headerFull {
        min-height: calc(100vh - 80px);
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .headerFull {
        padding-left: 25px;
        padding-right: 25px;
    }

    .intro h1 span {
        display: none;
    }

    .intro__image {
        margin-top: -1.5em;
    }
}

@media (min-width: 768px) {
    .intro h1 {
        font-size: 20vw;
    }

    .intro__title1 {
        margin-bottom: -30px;
    }

    .intro__title2 {
        margin-top: -100px;
    }

    .intro h1 br {
        display: none;
    }
    

    .intro__image {
        aspect-ratio: 592/379;
        width: clamp(30vw,70vw,60vh);
        margin-left: auto;
        margin-right: auto;
    }
}


@media (max-height: 860px) {
    .intro__title1 {
        margin-bottom: -10px;
    }

    .intro__title2 {
        margin-top: -80px;
    }
}

@media (min-width: 1024px) {
    .intro .headerFull {
        min-height: auto;
    }

    .intro h1 {
        font-size: 19.5vw;
        font-size: clamp(1vw,19.5vw,35vh);
        top: -5%;
    }

    .intro__title1 {
        margin-bottom: -100px;
    }

    .intro__title2 {
        margin-top: -100px;
    }
}

@media (min-width: 1240px) {
    .intro__title2 {
        margin-top: -150px;
    }
}

@media (min-width: 1600px) {
    .intro__title2 {
        margin-top: -200px;
    }
}


   