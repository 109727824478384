.text-block:nth-of-type(1) {
    position: relative;
}

.text-block:nth-of-type(2) {
    background-color: var(--white);
}

.text-block:nth-of-type(3) {
    
    background-color: var(--black);
    color: var(--white);
}

.text-block:nth-of-type(4) {
    background-color: var(--lightgreen);
    padding-bottom: 200px;
    padding-bottom: 200px;
    top: 0;
}

.text-block:nth-child(3) {
    z-index: 7;
}

.text-block:nth-child(4) {
    z-index: 5;
    flex-direction: row-reverse;
}

.text-block:nth-child(5) {
    z-index: 3;
}

.text-block:nth-child(6) {
    z-index: 1;
    flex-direction: row-reverse;
}

.text-block {
    background-color: var(--pink);
    color: var(--black);
    padding-top: 120px;
    padding-bottom: 120px;
    position: sticky;
    padding-left: 0;
    padding-right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100vw;
}

.text-block__half h3 {
    font-size: 6vw;
    margin: 0;
}

.text-block__half {
    padding: 100px;
    width: 50%;
}

section.loaded .appear {
    opacity: 1;
    transform: scale(1);
}

@media (max-width: 800px) {
    .text-block__half {
        padding-top: 0;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    } 

    section {
        flex-direction: column !important;
        align-items: center;
    }

    .text-block__half h3 {
        font-size: 5vw;
    }
}


@media (min-width: 1024px) {
    .text-block__half p {
        max-width: 511px;
        font-size: 20px;
    }

    .text-block__half h3 {
        font-size: 5vw;
    }
}

@media (min-width: 1600px) {
    .text-block__half h3 {
        font-size: 6vw;
    }
}

