section.loaded .appear {
    opacity: 1;
    transform: scale(1);
    transition: all .5s cubic-bezier(.5,1.51,.55,.89);
}

.tile {
    position: relative;
    margin-bottom: 24px;
    width: -webkit-fill-available;
    border-radius: 8px;
    padding: 0;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    color: var(--black);
    overflow: hidden;
}

.tile__container {
    height: 100%;
}

.tile__content {
    text-align: center;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.6s ease-in-out;
}

.tile__content h4 {
    display: inline-block;
    font-size: 14vw;
    padding: 10px 15px 1rem 15px;
    white-space: break-spaces;
    width: 90%;
}

.imgProject {
    width: 98%;
    height: 98%;
    border-radius: 4px;
    object-fit: cover;
    filter: grayscale(100%);
    position: absolute;
}

.tile__container:hover > .tile__content{
    transform: rotateY(180deg);
}

.thefront{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
  }

  .theback{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    background: var(--white);
    color: var(--black);
    text-align: center;
    transform: rotateY(180deg);
  }

  .backCardTitle {
    font-size: 8vw;
    padding: 10px;
  }

  .backCardText {
    font-size: 5vw;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 500px) {

    .backCardTitle {
        font-size: 8vw;
        padding: 15px;
    }
    
    .backCardText {
        font-size: 6vw;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 768px) {
    .tile {
        width: calc(50% - 12px);
    }

    .tile__content h4 {
        font-size: 8vw;
    }

    .backCardTitle {
        font-size: 5vw;
        padding: 20px;
    }
    
    .backCardText {
        font-size: 3vw;
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media (min-width: 1024px) {
    .tile {
        width: calc(33.333% - 15px);
    }

    .tile__content h4 {
        font-size: 5vw;
    }

    .backCardTitle {
        font-size: 4vw;
        padding: 10px;
    }
    
    .backCardText {
        font-size: 2vw;
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media (min-width: 1240px) {
    .tile__content h4 {
        font-size: 6vw;
    }
}

@media (min-width: 1600px) {

    .backCardTitle {
        font-size: 4vw;
        padding: 10px;
    }
    
    .backCardText {
        font-size: 2.2vw;
        padding-left: 20px;
        padding-right: 20px;
    }
}
