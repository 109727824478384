.carousel {
    position: relative;
    z-index: 11;
    margin-top: -1px;
    background-color: var(--black);
    color: var(--black);
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: .01em;
    padding: 51px 0 51px 0;
    overflow: hidden;
    width: 100vw;
    white-space: nowrap;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.carousel__tape {
    display: inline-block;
    min-width: 100vw;
    overflow: hidden;
    flex-shrink: 0;
    transform: translate3d(0,0,0);
    animation: marquee 60s linear infinite;
}

.carousel span {
    display: inline-block;
    font-weight: 800;
    vertical-align: middle;
    margin-right: 5em;
}

.carousel img {
    max-height: 50px;
    max-width: 125px;
    width: auto;
}


@media (min-width: 1024px) {
    .carousel {
        padding: 100px 0;
    }

    .carousel img {
        max-height: 90px;
        max-width: 246px;
    }
}

@media (min-width: 768px) {
    .carousel img {
        max-height: 67px;
        max-width: 167px;
    }
}

