

.tiles {
    width: 100vw;
    padding-top: 40px;
    position: relative;
    overflow: hidden;
    z-index: 3;
}

.tiles:before {
    content: '';
    display: block;
    position: absolute;
    width: 150vw;
    left: 50%;
    top: 0;
    height: 0;
    padding-bottom: 138%;
    transform: translateX(-50%);
    background-color: var(--black);
    border-radius: 150%;
    z-index: 0;
}

.tiles:after {
    content: '';
    display: block;
    position: absolute;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--black);
    z-index: -1;
}

.tiles.loaded .tiles__title {
    animation: chomp .6s 1s cubic-bezier(.5,1.51,.55,.89);
}

.tiles__title {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    height: auto;
    transform: scaleY(100%);
    transform-origin: bottom;
}

.projectsTitle {
    margin: auto;
    position: relative;
    text-align-last: center;
}

.project {
    min-height: 100vh;
}

.tiles__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: var(--black);
}




@media (min-width: 1024px) {
    .tiles {
        padding-top: 60px;
    }
}

@media (min-width: 1240px) {
    .tiles {
        padding-top: 100px;
    }
}
