#root {
    width: 100vw;
    min-height: 100vh;
    background-color: var(--white);
}

:root {
    --black : #042f1a;
    --pink: #ff73b5;
    --white: floralwhite;
    --lightgreen : lightgreen;

}

h1 {
    color: inherit;
    font-weight: 800;
    line-height: .85em;
    letter-spacing: .01em;
    text-align: inherit;
    margin: 0 0 0.2em 0;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h4 {
    color: var(--pink);
    font-weight: 800;
    line-height: .85em;
    letter-spacing: .01em;
    text-align: inherit;
    margin: 0 0 0.2em 0;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    z-index: 2;
}

img {
    max-width: 100%;
    height: auto;
    border-style: none;
}

section {
    position: relative;
}

ul {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: inherit;
}

li {
    font-size: 18px;
    line-height: 1.2em;
    margin: 0 1rem;
}

a {
    color: inherit;
    outline: none;
    text-decoration: none;
}

@keyframes marquee {
    0% {
        transform: translate3d(0%,0,0);
    }

    100% {
        transform: translate3d(-100%,0,0);
    }
}

@keyframes chomp {
    
    0% {
        transform: scaleY(100%);
    }
    25% {
        transform: scaleY(0);
    }
    50% {
        transform: scaleY(100%);
    }
    75% {
        transform: scaleY(0%);
    }
    100% {
        transform: scaleY(100%);
    }
}