.ticker {
    position: fixed;
    bottom: -10px;
    z-index: 91;
    background-color: var(--pink);
    color: var(--black);
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: .01em;
    padding: 0.7em 0 1.1em 0;
    overflow: hidden;
    width: 100vw;
    white-space: nowrap;
    font-size: 18px;
    transition: bottom .3s 1s cubic-bezier(.5,1.51,.55,.89);
}

.ticker__tape {
    display: inline-block;
    min-width: 100vw;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    -webkit-animation: marquee 40s linear infinite;
    -moz-animation: marquee 40s linear infinite;
    -o-animation: marquee 40s linear infinite;
    -ms-animation: marquee 40s linear infinite;
    animation: marquee 40s linear infinite;
}

.ticker span:after {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0 1.5em;
    background: no-repeat center center transparent;
    background-image: url('../../../public/icons/fleur.webp');
    background-size: contain;
    vertical-align: middle;
}




@media (min-width: 768px) {
    .ticker {
        font-size: 22px;
    }
}
